(() => {
  // handling lang toggling
  const handleLangToggle = () => {
    let $langEls: NodeListOf<HTMLAnchorElement>;
    let href = window.location.href as string;

    let hrefMap = {
      fr: {
        '/fr/pour-commencer': '/getting-started',
      },
      en: {
        '/getting-started': '/fr/pour-commencer',
      },
    };

    const handleAnchorClick = (e, $el) => {
      const elHref = $el.getAttribute('href') as string;

      e.preventDefault();
      let finalURL = '';
      const pathName =
        window.location.pathname !== '/' ? window.location.pathname : '';

      if (elHref.includes('fr')) {
        // if the current page is fr, and fr link is clicked, just reload the page. So the finalURL is going to be same as the current href
        if (href.includes('/fr')) {
          finalURL = href;
        } else {
          // if there is a mapped key for the current path, use it. Or else use the path name
          let frPath = hrefMap.en[pathName] || pathName || '';

          // making sure that /fr is not present in the path, to manually add it later
          frPath = frPath.replace('/fr', '');

          if (finalURL === '') {
            finalURL = `${window.location.origin}/fr${frPath}`;
          }
        }
      } else {
        let enPath = hrefMap.fr[pathName] || '';
        // if the current page is en, and en link is clicked, just reload the page. So the finalURL is going to be same as the current href
        if (href.includes('/fr') === false) {
          finalURL = href;
        } else {
          if (enPath) {
            finalURL = href.replace(pathName, enPath).replace('/fr', '/');
          } else {
            finalURL = href.replace('/fr', '/');
          }
        }
      }

      try {
        // replacing the double slash
        const url = new URL(finalURL);
        url.pathname = url.pathname.replaceAll('//', '/');
        finalURL = url.href;

        window.location.href = finalURL;
      } catch (e) {
        console.warn(e);
      }
    };

    const initVariables = () => {
      $langEls = document.querySelectorAll('.header__lang a');
    };

    const appendEvents = () => {
      $langEls.forEach($el => {
        $el.addEventListener('click', e => {
          handleAnchorClick(e, $el);
        });
      });
    };

    initVariables();
    appendEvents();
  };

  //handling link active state when clicked on find a clinic link
  const handleActiveLink = () => {
    let lastActiveItem = document.querySelector(
      '.emu-navigation__item--active > a'
    );

    lastActiveItem?.classList.add('is-active');
  };

  const init = () => {
    handleLangToggle();
    handleActiveLink();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
